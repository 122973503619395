<script setup>
const props = defineProps({
  value: {
    type: [Boolean, Number],
    default: false,
  },
  passiveText: {
    type: String,
    default: 'Pasive',
  },
  activeText: {
    type: String,
    default: 'Active',
  },
  activeColorClass: {
    type: String,
    default: 'bg-indigo-900 py-2 text-white',
  },
  passiveColorClass: {
    type: String,
    default: 'bg-gray-300 py-2',
  },
});
const emit = defineEmits(['update:value']);
const formValue = computed(() => props.value);

const toggle = (val) => {
  emit('update:value', val);
};
</script>
<template>
  <div class="grid grid-cols-2 cursor-pointer">
    <div :class="[!formValue ? activeColorClass : passiveColorClass]" @click="toggle(false)"
      class="w-full rounded-tl-md rounded-bl-md flex items-center justify-center font-bold">
      {{ passiveText }}
    </div>
    <div :class="[formValue ? activeColorClass : passiveColorClass]" @click="toggle(true)"
      class="w-full rounded-tr-md rounded-br-md flex items-center justify-center font-bold">
      {{ activeText }}
    </div>
  </div>
</template>
