<script setup>
import { useListsStore } from '~/stores/account/lists.store';
import { useUserStore } from '~/stores/user.store';

const listsStore = useListsStore();
const userStore = useUserStore();

const countries = computed(() => listsStore.countriesGetter());

const form = ref({
  mobile: undefined,
  country_id: undefined,
  show_mobile_in_listings: true,
});
const isLoading = ref(true);

onMounted(async () => {
  isLoading.value = false;
});

const submit = async () => {
  const res = await userStore.addMobile(form.value);
  if (res.status !== 200) return;
  form.value = {
    mobile: undefined,
    country_id: undefined,
    show_mobile_in_listings: true,
  };
  await userStore.getMobiles();
};
</script>
<template>
  <div class="px-4" v-if="!isLoading">
    <div class="mt-3">
      <h3 class="text-base text-indigo-900 leading-normal mb-1">{{ $t('client.mobile') }}</h3>
      <WInput type="text" name="mobile" :placeholder="$t('client.mobile')" v-model:value="form.mobile" />
    </div>
    <div class="mt-3">
      <h3 class="text-base text-indigo-900 leading-normal mb-1">{{ $t('client.select_country') }}</h3>
      <WInput :select="true" name="country_id" :options="countries" v-model:value="form.country_id" />
    </div>
    <div class="mt-3">
      <h3 class="text-base text-indigo-900 leading-normal mb-1">{{ $t('client.show_mobile_in_listings') }}</h3>
      <WToggle v-model:value="form.show_mobile_in_listings" passiveText="No" activeText="Yes" />
    </div>
    <div class="mt-6 flex items-center gap-3">
      <button @click="submit"
        class="bg-indigo-900 text-white rounded-lg p-3 capitalize  w-full text-center">{{ $t('client.update') }}</button>
    </div>
  </div>
  <template v-else>
    <div class="relative h-screen">
      <div class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
        <span class="loader"></span>
      </div>
    </div>
  </template>
</template>